<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置：<router-link tag="a" to="/">首页 </router-link>> 个人资料 >
        </div>

        <!-- 个人资料 -->
        <div class="class-box-1 sign-pro-1">
          <div class="class-box-1L">
            <div class="class-box-1L-1">个人资料</div>
            <div class="my">
              <el-form
                  ref="form"
                  :model="form"
                  label-width="20%"
                  :rules="formRules"
                  @submit.native.prevent
              >
                <div class="my-box">
                  <div class="my-L">
                    <div class="my-L-box">
                      <div class="my-L-box-1">
                        <!-- <img src="@/assets/image/profile.jpg" alt="" /> -->
                        <!-- <el-upload
                          class="avatar-uploader"
                          action="http://192.168.10.106:8082/dev-api/common/upload"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"

                        > -->
                        <el-upload
                            class="avatar-uploader"
                            action="/dev-api/common/upload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                        >
                          <img :src="avatar" />
                        </el-upload>
                      </div>
                      <!-- 修改 -->
                      <div class="my-txbtn">点击头像修改</div>
                    </div>
                  </div>
                  <!-- 姓名 性别 单位 邮箱 身份证 地址 发票抬头 -->
                  <!-- 张三 女 北京广电总局 leoemoji@163.com 441900164589652348 北京市海淀区西二旗育新路10号 -->
                  <div class="my-R">
                    <div class="my-Rbox">
                      <el-form-item label="姓名" prop="name">
                        <el-input
                            v-model="form.name"
                            class="my-R-1R my-name"
                            placeholder="请输入姓名"
                            clearable
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="性别" prop="sex">
                        <el-radio v-model="form.sex" label="0">男</el-radio>
                        <el-radio v-model="form.sex" label="1">女</el-radio>
                        <!-- <el-input
                              v-model="form.sex"
                              class="my-R-1R my-name"
                              placeholder="请输入性别"
                              clearable
                            ></el-input> -->
                      </el-form-item>
                      <el-form-item label="单位" prop="unit">
                        <el-input
                            v-model="form.unit"
                            class="my-R-1R my-name"
                            placeholder="请输入单位"
                            @blur="unitBlur"
                            clearable
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="邮箱" prop="email">
                        <el-input
                            v-model="form.email"
                            class="my-R-1R my-name"
                            placeholder="请输入邮箱"
                            clearable
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="证件">
                        <el-row>
                          <el-col :span="8">
                            <el-select class="my-R-1R my-name" style="width: 100%" v-model="form.codeType" placeholder="请选择证件类别">
                              <el-option
                                  v-for="dict in certificateTypeOptions"
                                  :key="dict.dictValue"
                                  :label="dict.dictLabel"
                                  :value="dict.dictValue"
                              ></el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="13">
                            <el-input
                                v-model="form.idCode"
                                class="my-R-1R my-name"
                                placeholder="请输入证件编号"
                                clearable
                            ></el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>

                      <el-form-item label="地址" prop="address">
                        <el-input
                            v-model="form.address"
                            class="my-R-1R my-name"
                            placeholder="请输入地址"
                            clearable
                        ></el-input>
                      </el-form-item>
                      <!-- <el-form-item label="发票抬头" prop="invoiceUp"> -->
                      <el-form-item label="发票抬头">
                        <el-input
                            v-model="form.invoiceUp"
                            class="my-R-1R my-name"
                            placeholder="请输入发票抬头"
                            clearable
                        ></el-input>
                      </el-form-item>
                    </div>
                    <!-- 修改 -->
                    <!-- <div class="my-btn" @click="submit()">保存</div> -->
                    <div class="my-btn" @click="submit('form')">保存</div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getStudent, updateStudent } from "@/api/bm/student";
// import axios from "axios";

export default {
  data() {
    return {
      sexOptions: [{}, {}],
      certificateTypeOptions: [],
      avatar: undefined,
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
      form: {
        id: undefined,
        name: undefined,
        sex: undefined,
        unit: undefined,
        email: undefined,
        idCode: undefined,
        address: undefined,
        invoiceUp: undefined,
        codeType: undefined,
        user: {
          id: undefined,
          userName: undefined,
          avatar: undefined,
        },
      },
      formRules: {
        // 姓名 单位 身份证 手机号
        // name unit idCode phone sex email address invoiceUp
        // 性别 邮箱 地址 发票抬头
        name: [
          { required: true, trigger: "blur", message: "请输入姓名" },
          { required: true, message: "请输入中文名", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        unit: [{ required: true, trigger: "blur", message: "请输入单位" }],
        idCode: [
          // { required: true, trigger: "blur", message: "身份证不能为空" },
          { required: true, message: "请输入身份证号", trigger: "blur" },

          {
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            required: true,
            pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, trigger: "blur", message: "请输入性别" }],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            required: true,
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "请输入正确的邮箱",
            trigger: "blur",
          },
        ],
        address: [{ required: true, trigger: "blur", message: "请输入地址" }],
        // invoiceUp: [
        //   { required: true, trigger: "blur", message: "请输入发票抬头" },
        // ],
      },
      //
    };
  },
  methods: {
    unitBlur(){
      // console.log(this.form);
      if(this.form.invoiceUp == undefined || this.form.invoiceUp == '')
        this.form.invoiceUp = this.form.unit;
    },
    getInfo() {
      getStudent().then((res) => {
        this.form = res.data;
        this.form.codeType = res.data.codeType || "JMSFZ";
        this.avatar = this.form.user.avatar || this.defaultAvatar();
        this.form.user.avatar = undefined;
      });
    },
    // submit() {
    //   console.log("提交成功");
    //   console.log(this.form);
    //   updateStudent(this.form).then((res) => {
    //     // Toast.success(res.msg);
    //     console.log(res);
    //     this.$message.success("保存成功");
    //     setTimeout(() => {
    //       this.$router.push({ path: "/my" });
    //     }, 2000);
    //   });
    // },
    // 提交
    submit(formName) {
      this.unitBlur();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("提交成功");
          console.log(this.form);
          updateStudent(this.form).then((res) => {
            // Toast.success(res.msg);
            console.log(res);
            this.$message.success("保存成功");
            setTimeout(() => {
              this.$router.push({ path: "/my" });
            }, 2000);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      //

      console.log("上传图片");
      console.log(res);
      console.log(res.fileName);
      console.log(file);
      this.avatar = this.form.user.avatar = res.fileName;
      console.log(this.avatar);
      // let formData = new FormData();
      // formData.append("file", file.file);
      // let url = "http://192.168.10.106:8082/dev-api/common/upload";
      // axios
      //   .post(url, formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res.data.url);
      //     this.avatar = this.form.user.avatar = res.data.fileName; //这里上传到指定的图片服务器，成功后会返回图片的url
      //   })
      //   .catch((err) => {
      //    console.log(err);
      //   });
    },
    // beforeAvatarUpload(file) {
    //   // const isJPG = file.type === 'image/jpeg';
    //   // const isLt2M = file.size / 1024 / 1024 < 2;

    //   // if (!isJPG) {
    //   //   this.$message.error('上传头像图片只能是 JPG 格式!');
    //   // }
    //   // if (!isLt2M) {
    //   //   this.$message.error('上传头像图片大小不能超过 2MB!');
    //   // }
    //   // return isJPG && isLt2M;
    // }
    // onRead(file) {
    //   let formData = new FormData();
    //   formData.append("file", file.file);
    //   let url = "/dev-api/common/upload";
    //   axios
    //     .post(url, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res.data.url);
    //       this.avatar = this.form.user.avatar = res.data.fileName; //这里上传到指定的图片服务器，成功后会返回图片的url
    //     })
    //     .catch((err) => {
    //       // Toast.fail(err);
    //     });
    // },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 进度条
    next() {
      if (this.active++ > 3) this.active = 0;
    },
  },
  created() {
    this.getInfo();
    this.getDicts("sys_user_sex").then((res) => {
      this.sexOptions = res.data;
    });
    this.getDicts("bm_certificate_class").then((res) => {
      this.certificateTypeOptions = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  background: #f8f8f8;
  padding-top: 26px;
  padding-bottom: 138px;
  display: flex;
  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    .class-nav {
      margin-bottom: 37px;
    }
    .class-box-1 {
      height: 236px;
      // border: 1px solid teal;
      .class-box-1L {
        width: 1300px;
        height: auto;
        background: #ffffff;
        float: left;
        // padding-bottom: 70px;
        // position: relative;
        .class-box-1L-1 {
          width: 1300px;
          height: 57px;
          line-height: 57px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 4px;
        }
        .class-box-1L-2 {
          width: 1300px;
          height: 175px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          position: relative;
          .class-box-1L-2-1 {
            padding-top: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              padding-right: 18px;
            }
          }
          .class-box-1L-2-4 {
            width: 190px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(
                    -54deg,
                    rgba(29, 112, 234, 0.89),
                    rgba(72, 140, 240, 0.89)
            );
            border-radius: 4px;
            position: absolute;
            top: 98px;
            right: 79px;
          }
        }
      }
      .class-box-1R {
        width: 262px;
        height: 236px;
        background: #ffffff;
        float: right;
        .class-box-1R-1 {
          width: 262px;
          height: 57px;
          line-height: 57px;
          padding-left: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
        }
        .class-box-1R-2 {
          img {
            width: 80px;
            height: 80px;
            background: #ffffff;
            border-radius: 50%;
            margin-left: 91px;
            margin-top: 24px;
          }
          .class-box-1R-2b {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 21px;
            .class-bs-1 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            }
            .class-bs-2 {
              width: 1px;
              height: 20px;
              background: #c0c0c0;
              margin: 0 18px;
              display: inline-block;
            }
            .class-bs-3 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }
        }
      }
    }
    .my {
      width: 1300px;
      height: 629px;
      background: #ffffff;
      box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
      padding-top: 77px;
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }
      .my-box {
        .my-L {
          width: 563px;
          height: 497px;
          border-right: 1px solid #d1d1d1;
          float: left;
          .my-L-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .my-L-box-1 {
              margin-top: 56px;
              img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                margin-bottom: 10px;
              }
            }
            .my-L-box-2 {
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }
          .my-txbtn {
            width: 198px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: linear-gradient(
                    -54deg,
                    rgba(30, 112, 234, 0.89),
                    rgba(72, 140, 240, 0.89)
            );

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            border-radius: 4px;
            cursor: pointer;
            margin-top: 10px;
          }
        }
        .my-R {
          width: 730px;
          height: 386px;
          float: left;
          .my-Rbox {
            padding-left: 0px;
            // border: 1px solid teal;
            ::v-deep .el-input__inner {
              -webkit-appearance: none;

              background: #f4f4f4;
              border-radius: 2px;
              background-image: none;
              border-radius: 4px;
              border: none;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              color: #606266;
              display: inline-block;
              font-size: inherit;
              height: 40px;
              line-height: 40px;
              outline: 0;
              padding: 0 15px;
              -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              width: 100%;
            }
            ::v-deep .el-input {
              position: relative;
              font-size: 14px;
              display: inline-block;
              width: 70%;
            }
            .my-R-1 {
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              //   .my-name {
              //     width: 203px;
              //   }
              .my-R-1L {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #b2b2b2;
                padding-right: 37px;
                text-align: right;
                width: 115px;
                display: inline-block;
              }
              ::v-deep .el-input--suffix .el-input__inner {
                padding-right: 30px;
                width: 412px;
                height: 44px;
                line-height: 44px;
                background: #f4f4f4;
                border-radius: 2px;
                border: none;
              }
              .my-R-1L2 {
                margin-left: -15px;
              }
            }
          }
          .my-btn {
            width: 198px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            background: linear-gradient(
                    -54deg,
                    rgba(29, 112, 234, 0.89),
                    rgba(72, 140, 240, 0.89)
            );
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 49px 0 0 107px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
